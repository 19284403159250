import 'flowbite';
import swal from 'sweetalert2';
import {Modal} from "flowbite";
import {createPopper} from "@popperjs/core";

window.Swal = swal;
window.createPopper = createPopper;

// Inicialização do Alpine e uso dele para abrir e fechar o modal
document.addEventListener('alpine:init', () => {
    const modal = document.getElementById('modal');
    const newModal = new Modal(modal);

    Alpine.data('modal', () => ({

        openModal: {
            ['x-ref']: 'openModal',
            ['x-on:click']() {
                newModal.show();
            },
        },
        closeModal: {
            ['x-ref']: 'closeModal',
            ['x-on:click']() {
                newModal.hide();
            },
        },
    }))

    Livewire.on('saved', () => {
        newModal.hide();
    });

    Livewire.on('openModal', () => {
        newModal.show();
    });
})